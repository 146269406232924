<template>
  <div class="iss-main theme-bg" ref="container">
    <div class="dataRecognition-content">
      <tips-bar
        text="识别到联系人姓名、公司、职位信息连写；已为您自动拆分请确认是否准确。"
        v-if="state.showIdentify === 'AI'"
      >
        <template #btnList>
          <div class="btn" @click="clickHandle('a')">下一步</div>
        </template>
      </tips-bar>

      <tips-bar text="请手动拆分姓名、公司、职位连写的联系人信息。" v-else>
        <template #btnList>
          <div class="btn" @click="clickHandle('b')">下一步</div>
        </template>
      </tips-bar>
      <div class="operation">
        <div class="operation-tips mb-10">
          <a-alert
            message="蓝色为姓名、黄色为公司名、紫色为职位名；点击编辑可手动调整拆分的数据。"
            type="error"
            v-if="!tipsClose"
            showIcon
            closable
            :afterClose="afterClose"
          >
            <template #icon><ExclamationCircleOutlined /></template>
            <template #closeText class='textColor'>不再提示</template>
          </a-alert>
        </div>
        <div class="operation-header">
          <div class="header-text">
            <a-radio-group
              v-model:value="state.typeValue"
              button-style="solid"
            >
              <a-radio-button value="0">未处理</a-radio-button>
              <a-radio-button value="1">已处理</a-radio-button>
            </a-radio-group>
          </div>
        </div>

        <div class="operation-content">
          <div class="operation-table">
            <grid
              ref="gridRef"
              :columns="columns"
              :code="$route.path"
              :urlParams="{
                userId: $store.state.account.user.id,
                identify: state.typeValue,
                importSituation: $route.query.importStatus,
              }"
              formWidth="300"
              :showAllSelect="false"
              :items="items"
              :url="url"
              :btn-operation="btnOperation"
              allowSelection
              :scroll="{ y: gridHeight }"
              @fnGrid="getGridData"
            >
              <template #allName="{ record }">
                <span
                  :title="
                    (record.name || '') +
                    ' ' +
                    (record.company || '') +
                    ' ' +
                    (record.position || '')
                  "
                >
                  {{
                    (record.name || '') +
                    ' ' +
                    (record.company || '') +
                    ' ' +
                    (record.position || '')
                  }}
                </span>
              </template>
              <template #name="{ text }">
                <span
                  class="table-item item1"
                  v-if="text.name?.length"
                  :title="text.name"
                >
                  <span>{{ text.name }}</span>
                </span>
              </template>
              <template #company="{ text }">
                <div
                  class="table-item item2"
                  v-if="text.company?.length"
                  :title="text.company"
                >
                  <span>{{ text.company }}</span>
                </div>
              </template>
              <template #position="{ text }">
                <div
                  class="table-item item3"
                  v-if="text.job?.length"
                  :title="text.job"
                >
                  <span>{{ text.job }}</span>
                </div>
              </template>
              <template #operation="{ record }">
                <operation :options="options" :record="record" />
              </template>
            </grid>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    title="手动调整"
    v-model:visible="state.visible"
    :getContainer="container"
    okText="确认"
    :width="700"
    @ok="editPeople"
  >
    <div class="mb-20">
      <ExclamationCircleFilled class="l-text-primary fs-16" />
      <span class="fs-14 l-text-primary">
        您可选择拆分类型后涂抹信息字段，将自动拆分至相应字段中；您也可以编辑相关信息，若您编辑信息
        后再次涂抹选择您输入的信息将被清空。
      </span>
    </div>
    <a-form
      ref="formRef"
      layout="vertical"
      style="width: 100%"
      :model="formState.nlpText"
      :rules="rules"
    >
      <a-form-item label="拆分信息">
        <a-radio-group v-model:value="state.splitType">
          <a-radio value="姓名" class="radio-name">姓名</a-radio>
          <a-radio value="公司" class="radio-company">公司</a-radio>
          <a-radio value="职位" class="radio-position">职位</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="全部信息">
        <div
          class="info-list"
          @mousedown="state.showIcon = true"
          @mouseup="state.showIcon = false"
        >
          <div
            v-for="(i, index) in state.splitList"
            :key="index"
            class="info-item"
            :class="itemType(index)"
            @mouseenter="addValue(i, index)"
            @mousedown="
              state.showIcon = true;
              addValue(i, index);
            "
            @mouseup="
              state.lock = false;
              state.showIcon = false;
            "
            @mouseleave="state.lock = false"
          >
            <span>{{ i }}</span>
          </div>
        </div>
      </a-form-item>
      <a-form-item label="姓名" name="name">
        <a-input
          v-model:value="formState.nlpText.name"
          @focus="handleBlur('姓名')"
        />
      </a-form-item>
      <a-form-item label="公司" name="company">
        <a-input
          v-model:value="formState.nlpText.company"
          @focus="handleBlur('公司')"
        />
      </a-form-item>
      <a-form-item label="职位" name="job">
        <a-input
          v-model:value="formState.nlpText.job"
          @focus="handleBlur('职位')"
        />
      </a-form-item>
    </a-form>
    <div
      class="follower"
      v-show="state.showIcon"
      :class="mouseType"
      :style="{ top: mouseY - 7 + 'px', left: mouseX - 9 + 'px' }"
    ></div>
  </a-modal>
</template>

<script>
import tipsBar from './components/tipsBar';
import { useRouter, useRoute } from 'vue-router';
import Grid from '@/components/connGrid';
import {
  reactive,
  ref,
  toRaw,
  watch,
  computed,
  onMounted,
  onBeforeUnmount,
  createVNode, getCurrentInstance
} from 'vue';
import { Form, message, Alert, Radio, Modal } from 'ant-design-vue';
import operation from '@/components/operation';
import dashboardApi from '@/api/dashboard.js';
import {
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue';
import { local } from '@/utils/storage';
import { generateRangeArray } from '@/utils';
import { useStore } from 'vuex';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'dataRecognition',
  components: {
    tipsBar,
    Grid,
    AForm: Form,
    AFormItem: Form.Item,
    AAlert: Alert,
    ARadio: Radio,
    ARadioButton: Radio.Button,
    ARadioGroup: Radio.Group,
    operation,
    ExclamationCircleOutlined,
    ExclamationCircleFilled,
  },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const store = useStore();
    const formRef = ref();
    const gridRef = ref();
    const tipsClose = ref(local.get('iss-dataRecognition-tips'));
    const container = ref();

    const state = reactive({
      visible: false,
      loading: false,
      showIcon: false,
      lock: false,
      typeValue: '0',
      splitType: '姓名',
      splitName: [],
      splitCompany: [],
      splitPosition: [],
      splitList: [],
      showIdentify: '',
      showTip: null,
      search: {},
    });
    const formState = reactive({
      nlpText: {
        name: '',
        company: '',
        job: '',
      },
    });
    const rules = {
      name: [
        {
          required: true,
          message: '请输入姓名',
          trigger: 'change',
        },
        {
          max: 16,
          message: '姓名长度不可超过16',
          trigger: 'change',
        },
      ],
      company: [
        {
          max: 64,
          message: '公司长度不可超过64',
          trigger: 'change',
        },
      ],
      position: [
        {
          max: 64,
          message: '职位长度不可超过64',
          trigger: 'change',
        },
      ],
    };
    const mouseX = ref(0);
    const mouseY = ref(0);
    const btnList = [
      {
        type: 'selectAll',
        label: '跨页全选',
        permission: 'resources:selectAll',
        fnClick: () => {
          // console.log('全选', gridRef.value.getSelectedRowsKeys());
        },
      },
      {
        label: '确认无误',
        icon: '',
        permission: 'clue:update',
        fnClick: (ids, data) => {
          // console.log('ids', ids)
          // ids
          // if (ids.length) {
            const arr = data.filter(i => {
              return ids.includes(i.id);
            });
          // console.log('arr', arr)
            let parmas = {
              importSituation: route.query.importStatus,
              input: state.search.name,
              selectAll: gridRef.value.selectAll().selectAll,
            }

            if (gridRef.value.selectAll().selectAll) {
              parmas.importContactIdList = gridRef.value.selectAll().contactId
            } else {
              parmas.marksList = arr
            }
            dashboardApi
              .updateRecognitions(parmas)
              .then(res => {
                if (res) {
                  state.loading = false;
                  message.success('修改成功');
                  gridRef.value.refreshGrid(parmas.importContactIdList?.length || parmas.marksList?.length);
                }
              })
              .catch(() => {
                message.error('修改失败');
                state.loading = false;
              });

          // }
        },
      },
    ];
    const btnOperation = ref(btnList);
    const handleMouseMove = event => {
      mouseX.value = event.clientX;
      mouseY.value = event.clientY;
    };
    const afterClose = () => {
      local.set('iss-dataRecognition-tips', true);
    };
    const itemType = computed(() => index => {
      if (state.splitName.includes(index)) {
        return 'item1';
      } else if (state.splitCompany.includes(index)) {
        return 'item2';
      } else if (state.splitPosition.includes(index)) {
        return 'item3';
      } else {
        return '';
      }
    });
    const mouseType = computed(() => {
      switch (state.splitType) {
        case '姓名':
          return 'item1';
        case '公司':
          return 'item2';
        case '职位':
          return 'item3';
        default:
          return '';
      }
    });
    const addValue = (item, index) => {
      // console.log('item', item, index);
      if (state.lock || !state.showIcon) return;
      state.lock = true;
      const iN = state.splitName.indexOf(index);
      const iC = state.splitCompany.indexOf(index);
      const iP = state.splitPosition.indexOf(index);
      if (state.splitType === '姓名') {
        if (iC > -1) {
          state.splitCompany.splice(iC, 1);
        } else if (iP > -1) {
          state.splitPosition.splice(iP, 1);
        }
        if (iN !== -1) {
          state.splitName.splice(iN, 1);
        } else {
          state.splitName.push(index);
        }
        formState.nlpText.name = state.splitName
          .sort((a, b) => a - b)
          .map(i => state.splitList[i])
          .join('');
      } else if (state.splitType === '公司') {
        if (iN > -1) {
          state.splitName.splice(iN, 1);
        } else if (iP > -1) {
          state.splitPosition.splice(iP, 1);
        }
        if (iC !== -1) {
          state.splitCompany.splice(iC, 1);
        } else {
          state.splitCompany.push(index);
        }
        formState.nlpText.company = state.splitCompany
          .sort((a, b) => a - b)
          .map(i => state.splitList[i])
          .join('');
      } else if (state.splitType === '职位') {
        if (iN > -1) {
          state.splitName.splice(iN, 1);
        } else if (iC > -1) {
          state.splitCompany.splice(iC, 1);
        }
        if (iP !== -1) {
          state.splitPosition.splice(iP, 1);
        } else {
          state.splitPosition.push(index);
        }
        formState.nlpText.job = state.splitPosition
          .sort((a, b) => a - b)
          .map(i => state.splitList[i])
          .join('');
      }
    };
    // todo AI做完再去测这个步骤
    const clickHandle = type => {
      if (type === 'a') {
        // typeValue 是0 并且列表是有数据才需要提示
        if (state.showTip && state.typeValue === '0') {
          Modal.confirm({
            title: `还有联系人信息未整理完您确认进入下一步吗？`,
            icon: createVNode(icons['ExclamationCircleOutlined']),
            okText: '确认',
            onOk() {
              router.push({
                name: 'correction',
                query: route.query,
              });
            },
          });
        } else {
          router.push({
            name: 'correction',
            query: route.query,
          });
        }
      } else {
        router.push({
          name: 'correction',
          query: route.query,
        });
      }
    };
    proxy.$bus.on('CONN-SEARCH', obj => {
      state.search = obj;
    });
    const editPeople = () => {
      formRef.value.validateFields().then(() => {
        if (state.loading) return;
        state.loading = true;
        formState.identify = 1;
        dashboardApi
          .updateRecognition({
            ...toRaw(formState),
          })
          .then(res => {
            if (res) {
              message.success('编辑成功');
              state.loading = false;
              state.visible = false;
              gridRef.value.refreshGrid();
            }
          })
          .catch(() => {
            message.error('操作失败');
            state.loading = false;
          });
      });
    };

    // 查询联系人信息是否自动识别
    dashboardApi
      .getIdentifyAutomatic({
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
      })
      .then(res => {
        if (res) {
          // console.log('res66', res);
          state.showIdentify = res;
        }
      });

    onMounted(() => {
      window.addEventListener('mousemove', handleMouseMove);
    });
    // 在组件销毁之前移除事件
    onBeforeUnmount(() => {
      window.removeEventListener('mousemove', handleMouseMove);
    });
    watch(
      () => state.visible,
      val => {
        if (!val) {
          formRef.value.clearValidate();
          formRef.value.resetFields();
          state.lock = false;
          state.showIcon = false;
          state.splitType = '姓名';
          state.splitList = [];
          state.splitName = [];
          state.splitCompany = [];
          state.splitPosition = [];
        }
      }
    );
    // 已处理和未处理切换

    watch(
      () => state.typeValue,
      val => {
        btnOperation.value = val == '0' ? btnList : [];

      }
    );

    // 设置用户导入行为页面信息 /dashboard/dataRecognition  设置7
    dashboardApi
      .setBehaviorPage({
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
        pageName: '/dashboard/dataRecognition',
      })
      .then(() => {});

    return {
      editPeople,
      clickHandle,
      itemType,
      mouseType,
      formRef,
      tipsClose,
      mouseX,
      mouseY,
      container,
      btnOperation,
      addValue,
      afterClose,
      state,
      gridRef,
      rules,
      formState,
      url: '/connections_treasure_box/contact/import-contact/getEffectiveInfoPage',
      gridHeight: document.body.clientHeight - 280,
      items: [{ key: 'input', label: '搜索姓名、公司、职位' }],
      columns: [
        {
          title: '全部信息',
          dataIndex: 'id',
          slots: { customRender: 'allName' },
          ellipsis: true,
        },
        {
          title: '姓名',
          dataIndex: 'nlpText',
          width: 180,
          slots: { customRender: 'name' },
          ellipsis: true,
        },
        {
          title: '公司',
          dataIndex: 'nlpText',
          width: 180,
          slots: { customRender: 'company' },
          ellipsis: true,
        },
        {
          title: '职位',
          dataIndex: 'nlpText',
          width: 150,
          slots: { customRender: 'position' },
          ellipsis: true,
        },
        {
          key: 'id',
          fixed: 'right',
          width: 90,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'delete',
          permission: 'form:delete',
          fnClick: record => {
            console.log('delete', record);

            dashboardApi
              .deletePeople({
                userId: store.state.account.user.id,
                ids: [record.id],
              })
              .then(res => {
                if (res) {
                  message.success('删除成功');
                  state.loading = false;
                  gridRef.value.refreshGrid([record.id].length);
                }
              })
              .catch(() => {
                message.error('操作失败');
                state.loading = false;
              });
          },
        },
        {
          type: 'update',
          label: '编辑',
          permission: 'dataRecognition:update',
          fnClick: record => {
            const name = record.name || '';
            const company = record.company || '';
            const job = record.position || '';
            const nameN = record.nlpText.name || '';
            const companyN = record.nlpText.company || '';
            const jobN = record.nlpText.job || '';
            const str = name + company + job;
            state.visible = true;
            state.splitList = str.split('') || [];
            state.splitName = generateRangeArray(
              str.indexOf(nameN),
              str.indexOf(nameN) + nameN.length - 1
            );
            state.splitCompany = generateRangeArray(
              str.indexOf(companyN),
              str.indexOf(companyN) + companyN.length - 1
            );
            state.splitPosition = generateRangeArray(
              str.lastIndexOf(jobN),
              str.lastIndexOf(jobN) + jobN.length - 1
            );
            Object.assign(formState, JSON.parse(JSON.stringify(record)));
            console.log(formState);
          },
        },
      ],
      getGridData(value) {
        let len = value?.length ? value?.length : value.records?.length;
        if (len > 0) {
          state.showTip = true;
        }
      },
      handleBlur(type) {
        console.log('type', type);
        state.splitType = type;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.theme-bg {
  background: linear-gradient(245deg, #ffedde 1%, #fff8f1 100%);
}
.dataRecognition-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 40px;
}
.btn {
  width: 100px;
  height: 42px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
}
.operation {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  .operation-content {
    position: relative;
    .operation-header {
      display: inline-block;
      position: absolute;
      .header-text {
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
  }
}
.table-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
}

.info-list {
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .info-item {
    box-sizing: content-box;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    background-color: rgb(235, 235, 235);
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

:deep(.ant-alert-message),
:deep(.ant-alert-close-text) {
  color: #fa5151;
  font-size: 13px;
}
:deep(.ant-alert) {
  margin-bottom: 20px;
}
.item1 {
  background-color: #c3d0f9 !important;
}
.item2 {
  background: #f6f651 !important;
}
.item3 {
  background: #facff9 !important;
}
.follower {
  position: fixed;
  padding: 10px;
  border-radius: 50%;
  z-index: 999;
  pointer-events: none; /* 将点击事件透明传递给底层元素 */
}
:deep(.radio-name .ant-radio-checked::after),
:deep(.radio-name .ant-radio-checked .ant-radio-inner) {
  border-color: #c3d0f9;
}
:deep(.radio-name .ant-radio-inner::after) {
  background-color: #c3d0f9;
}
:deep(.radio-company .ant-radio-checked::after),
:deep(.radio-company .ant-radio-checked .ant-radio-inner) {
  border-color: #f0f035;
}
:deep(.radio-company .ant-radio-inner::after) {
  background-color: #f0f035;
}
:deep(.radio-position .ant-radio-checked::after),
:deep(.radio-position .ant-radio-checked .ant-radio-inner) {
  border-color: #facff9;
}
:deep(.radio-position .ant-radio-inner::after) {
  background-color: #facff9;
}
:deep(.ant-radio-input:focus + .ant-radio-inner) {
  box-shadow: none;
}
.iss-main /deep/ .ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: break-spaces;
  text-overflow: ellipsis;
}
.operation-table /deep/ .ant-table {
  max-height: calc(100vh - 476px);
  overflow: auto;
}
</style>
